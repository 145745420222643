<template>
  <div>
    <div id="more-menu">
      <div class="more-menu-content">
        <div class="text-white close-more-menu">
          <a
            href="#"
            class="text-white float-end"
            @click.prevent="toggleMoreMenu"
            ><i class="lni lni-close"></i
          ></a>
        </div>
        <ul>
          <li><a href="/">Properties</a></li>
          <li><a href="/bnb">RealBnB</a></li>
          <li><a href="/sharespace">ShareSpace</a></li>
          <li><a href="/seller-plans">Seller Plans</a></li>
          <li><a href="/testimonials">Testimonials</a></li>
          <li><a href="/painting-and-cleaning">Painting & Cleaning</a></li>
          <li><a href="/legal-and-surveying">Legal & Surveying</a></li>
          <li><a href="/careers">Careers</a></li>
          <li><a href="/pricing">Pricing</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/contact-us">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <div class="real-partners">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 contacts">
            <a href="#"
              ><i class="lni lni-phone me-2"></i>{{ contact.phone_number }}</a
            >
            <a href="#"
              ><i class="lni lni-envelope me-2"></i>{{ contact.email }}</a
            >
          </div>
          <div class="col-md-6 text-end text-right">
            <a href="/refer-and-earn" v-if="$route.name != 'refer-and-earn'">Refer & Earn</a>
            <a href="/" v-if="$route.name == 'movers' || $route.name == 'field-assistants' || $route.name == 'refer-and-earn' || $route.name == 'seller-plans' || $route.name == 'testimonials' || $route.name == 'painting-and-cleaning' || $route.name == 'careers'">Properties</a>
            <a href="/movers" v-if="$route.name != 'movers'">Movers</a>
            <a href="/field-assistants" v-if="$route.name != 'field-assistants'">Field Assistants</a>
            <a href="#" @click.prevent="toggleMoreMenu"
              ><i class="lni lni-plus me-2"></i> More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleMoreMenu() {
      document.getElementById('more-menu').classList.toggle('show')
    }
  },
  
  computed: {
    contact() {
      return  this.$store.getters.getContact
    },
  }
};
</script>

<style lang="scss" scoped>
.me-2 {
  margin-right: 10px;
}

.float-end {
  float: right;
}

#more-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: none;

  &.show {
    display: block;
  }

  .close-more-menu {
    padding: 15px 0;
  }

  .more-menu-content {
    background: #4a4a4a;
    width: 250px;
    padding: 0 30px;
    float: right;
    height: 100%;
    border-left: solid 2px #ddd;

    @media(max-width: 768px) {
      width: 80%;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        line-height: 50px;

        a {
          color: #fff;
          display: block;
          border-bottom: solid 1px #fff;

          &:hover {
            color: #fff;
          }
        }

        &:first-child {
          a {
            border-bottom: none;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.real-partners {
  background: #3d0859;
  padding: 5px 0;
  font-size: 14px;

  @media(max-width: 768px) {
    .contacts {
      display: none;
    }

    .text-end.text-right {
      text-align: center !important;

      a {
        padding: 0 8px;
        font-size: 14px;

        i {
          margin-right: 2px !important;
        }

        &:nth-child(3) {
          display: none;
        }
      }
    }

    .container-fluid {
      padding: 0;
    }
  }

  a {
    color: #fff;
    padding: 0 15px;

    &:hover {
      color: #fff;
    }
  }
}
</style>