<template>
  <div>
    <footer-tags />

    <footer class="dark-footer skin-dark-footer">
      <div>
        <partners />

        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="footer-widget">
                <img
                  src="/logo.png"
                  class="img-footer bg-white rounded"
                  alt=""
                />
                <div class="footer-add">
                  <p class="mb-1">
                    <i class="lni lni-phone me-2"></i>
                    {{ contact.phone_number }}
                  </p>
                  <p class="mb-1">
                    <i class="lni lni-envelope me-2"></i> {{ contact.email }}
                  </p>
                  <p class="mb-1">
                    <i class="lni lni-home me-2"></i> {{ contact.location }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-6">
              <div class="footer-widget">
                <h4 class="widget-title">Navigations</h4>
                <ul class="footer-menu">
                  <li>
                    <router-link :to="{ name: 'about-us' }"
                      >About Us</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'faqs' }">FAQs</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'contact-us' }"
                      >Contact Us</router-link
                    >
                  </li>
                  <li>
                    <a href="https://realhub.co.ke/blog" target="_blank"
                      >Blog</a
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'testimonials' }"
                      >Testimonials</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <div class="footer-widget">
                <h4 class="widget-title">Documents</h4>
                <ul class="footer-menu">
                  <li>
                    <router-link :to="{ name: 'terms-and-conditions' }">Terms And Conditions</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'return-and-refund-policy' }">Return And Refund Policy</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'cookie-policy' }">Cookie Policy</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'disclaimer' }">Disclaimer</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-6 col-6">
              <div class="footer-widget">
                <h4 class="widget-title">My Account</h4>
                <ul class="footer-menu">
                  <li>
                    <router-link :to="{ name: 'my-account.profile' }"
                      >My Profile</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my-account.home' }"
                      >My account</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my-listings.index' }"
                      >My Listings</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my-account.bookmarks' }"
                      >Bookmarks</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'pricing' }">Pricing</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6">
              <div class="footer-widget">
                <h4 class="widget-title">Join Us Online</h4>
                <ul class="footer-menu socials">
                  <li>
                    <a
                      :href="contact.twitter"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-twitter mr-2"></i> Twitter</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.facebook"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-facebook mr-2"></i> Facebook</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.youtube"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-youtube mr-2"></i> Youtube</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.tiktok"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-tiktok mr-2"></i> TikTok</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.instagram"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-instagram mr-2"></i> Instagram</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.linkedin"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-linkedin mr-2"></i> LinkedIn</a
                    >
                  </li>
                  <li>
                    <a
                      :href="contact.pinterest"
                      target="_blank"
                      class="h2 text-white-800"
                      ><i class="lni lni-pinterest mr-2"></i> Pinterest</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <p class="mb-0">
                © 2023 RealHub. Designed By
                <a href="">LocalNet</a> All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import FooterTags from "./FooterTags";

export default {
  components: {
    FooterTags,
  },

  computed: {
    categories() {
      return this.$store.getters.getMenuCategories;
    },

    contact() {
      return this.$store.getters.getContact;
    }
  },
};
</script>
