<template>
  <div class="has-edit">
    <edit-button v-if="!editing" @click="editing = true" />
    <a
      href="#"
      v-if="editing"
      class="btn btn-warning float-end"
      @click.prevent="editing = false"
      >Back</a
    >
    <template v-if="!editing && !loading">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="(item, i) in menu" :key="`item-${i}`">
          <a
            :class="`nav-link ${i == 0 ? 'activ' : ''}`"
            data-bs-toggle="tab"
            :href="`#item${i}`"
            >{{ item.title }}</a
          >
        </li>
      </ul>

      <div class="tab-content">
        <div
          v-for="(item, i) in menu"
          :key="`item${i}`"
          :class="`tab-pane pt-3 ${i == 0 ? 'activ' : ''}`"
          :id="`item${i}`"
        >
          <div class="row">
            <div
              class="col-md-3"
              v-for="(child, j) in item.children"
              :key="`child${i}${j}`"
            >
              <h6>
                <router-link :to="{ path: child.url }">{{
                  child.title
                }}</router-link>
              </h6>
              <ul class="nav">
                <li class="nav-item">
                  <router-link
                    class="nav-link ps-0"
                    v-for="(cld, k) in child.children"
                    :key="`cld${i}${j}${k}`"
                    :to="{ path: cld.url }"
                    >{{ cld.title }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="editing && !loading">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="(item, i) in menu" :key="`item-${i}`">
          <a
            :class="`nav-link ${i == 0 ? 'active' : ''}`"
            data-bs-toggle="tab"
            :href="`#item${i}`"
            >{{ item.title }}</a
          >
          <div class="text-center">
            <a href="#" class="deleteitem" @click.prevent="deleteItem(i)"
              ><i class="lni lni-trash-can"></i
            ></a>
            <a href="#" class="deleteitem" @click.prevent="edit_item = item; editing_item = true; edit_item_index = i"
              ><i class="lni lni-pencil"></i
            ></a>
          </div>
        </li>
        <li class="nav-item">
          <a href="#" @click.prevent="show_add_menu = true" class="nav-link"
            ><i class="lni lni-plus me-2"></i>Add</a
          >
        </li>
      </ul>

      <div class="tab-content">
        <div
          v-for="(item, i) in menu"
          :key="`item${i}`"
          :class="`tab-pane pt-3 ${i == 0 ? 'active' : ''}`"
          :id="`item${i}`"
        >
          <div class="row">
            <div
              class="col-md-3"
              v-for="(child, j) in item.children"
              :key="`child${i}${j}`"
            >
              <h6>
                <router-link to="">{{ child.title }}</router-link>
              </h6>
              <ul class="nav">
                <li class="nav-item">
                  <a
                    class="nav-link ps-0"
                    v-for="(cld, k) in child.children"
                    :key="`cld${i}${j}${k}`"
                    href="#"
                    >{{ cld.title }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>

    <modal :show="show_add_menu" @close="show_add_menu = false" v-if="!loading">
      <template slot="header">Add Footer Tag Item</template>
      <form @submit.prevent="addTag">
        <div class="form-group">
          <label>Title</label>
          <input
            type="text"
            class="form-control"
            placeholder="Title"
            v-model="title"
            required
          />
        </div>
        <div class="form-group">
          <label>Select Type</label>
          <select class="form-control" v-model="selected_type">
            <option value="buy">For Sale</option>
            <option value="rent">For Rent</option>
            <option value="bnb">RealBnB</option>
            <option value="sharespace">Sharespace</option>
          </select>
        </div>
        <div class="form-group">
          <label>Select Category</label>
          <v-select
            class="form-control"
            label="name"
            v-model="selected_category"
            :options="categories"
            placeholder="Select Category"
          />
        </div>
        <div class="form-group">
          <label>Select Regions</label>
          <v-select
            class="form-control"
            label="name"
            v-model="selected_regions"
            :options="regions"
            placeholder="Select Regions"
            :multiple="true"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Add</button>
        </div>
      </form>
    </modal>

    <modal :show="editing_item" @close="editing_item = false" v-if="!loading">
      <template slot="header">Edit Footer Tag Item</template>
      <form @submit.prevent="editTag">
        <div class="form-group">
          <label>Title</label>
          <input
            type="text"
            class="form-control"
            placeholder="Title"
            v-model="edit_item.title"
            required
          />
        </div>
        <div class="form-group">
          <label>Select Type</label>
          <select class="form-control" v-model="edit_item.type">
            <option value="buy">For Sale</option>
            <option value="rent">For Rent</option>
            <option value="bnb">RealBnB</option>
            <option value="sharespace">Sharespace</option>
          </select>
        </div>
        <div class="form-group">
          <label>Select Category</label>
          <v-select
            class="form-control"
            label="name"
            v-model="edit_item.category"
            :options="categories"
            placeholder="Select Category"
          />
        </div>
        <div class="form-group">
          <label>Select Regions</label>
          <v-select
            class="form-control"
            label="name"
            v-model="edit_item.regions"
            :options="regions"
            placeholder="Select Regions"
            :multiple="true"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      editing: false,
      loading: true,
      categories: [],
      regions: [],
      menu: [],
      title: "",
      show_add_menu: false,
      selected_category: null,
      selected_type: null,
      selected_regions: [],
      edit_item: {},
      editing_item: false,
      edit_item_index: null
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.axios.get("/api/v1/footer-tags").then((response) => {
        this.categories = response.data.categories;
        this.regions = response.data.regions;
        this.menu = response.data.menu;
        this.loading = false;
      });
    },

    addTag() {
      this.loading = true;
      let data = {
        action: "add",
        title: this.title,
        type: this.selected_type,
        category_id: this.selected_category.id,
        region_ids: this.selected_regions.map((region) => {
          return region.id;
        }),
      };

      this.$axios.post("/api/v1/footer-tags", data).then(() => {
        this.show_add_menu = false;
        this.fetch();
      });
    },

    editTag() {
      this.loading = true;
      let data = {
        action: "edit",
        index: this.edit_item_index,
        title: this.edit_item.title,
        type: this.edit_item.type,
        category_id: this.edit_item.category.id,
        region_ids: this.edit_item.regions.map((region) => {
          return region.id;
        }),
      };

      this.$axios.post("/api/v1/footer-tags", data).then(() => {
        this.editing_item = false;
        this.fetch();
      });
    },

    deleteItem(i) {
      this.loading = true;

      let data = {
        action: "delete",
        index: i,
      };

      this.$axios.post("/api/v1/footer-tags", data).then(() => {
        this.show_add_menu = false;
        this.fetch();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.deleteitem {
  color: red !important;
  display: inline-block;
  text-align: center;
  margin: 0 10px;
}

.nav-tabs {
  border: none;
  background: #eee;

  .nav-link {
    background: #eee;

    &.active {
      border: none;
      background: #fff;
    }
  }
}

.tab-content {
  border: none;
  padding: 0;

  .active {
    padding: 15px;
  }

  .nav {
    .nav-item {
      .nav-link {
        font-size: 14px;
        color: rgba(#404040, .8)
      }
    }
  }
}
</style>
