<template>
  <div>
    <top-bar />

    <div class="header header-light dark">
      <div class="container-fluid">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="/">
              <img src="/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
          </div>
          <div class="nav-menus-wrapper" style="transition-property: none">
            <ul class="nav-menu">
              <li>
                <router-link :to="{ name: 'home' }">Home</router-link>
              </li>
              <li>
                <a href="JavaScript:Void(0);"
                  >Properties<span class="submenu-indicator"></span
                ></a>
                <ul class="nav-dropdown nav-submenu">
                  <li>
                    <router-link :to="{ name: 'page', params: { slug: 'buy' } }"
                      >For Sale</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'page', params: { slug: 'rent' } }"
                      >For Rent</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'page', params: { slug: 'bnb' } }"
                      >RealBnB</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'page', params: { slug: 'sharespace' } }"
                      >ShareSpace</router-link
                    >
                  </li>
                </ul>
              </li>

              <li>
                <a href="JavaScript:Void(0);"
                  >Categories<span class="submenu-indicator"></span
                ></a>
                <ul class="nav-dropdown nav-submenu">
                  <li
                    v-for="(category, i) in categories"
                    :key="`category-${i}`"
                  >
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: category.slug },
                      }"
                      >{{ category.name
                      }}<span
                        class="submenu-indicator"
                        v-if="category.children.length > 0"
                      ></span
                    ></router-link>
                    <ul
                      class="nav-dropdown nav-submenu"
                      v-if="category.children.length > 0"
                    >
                      <li
                        v-for="(child, j) in category.children"
                        :key="`child-${(j + 1) * (i + 1)}`"
                      >
                        <router-link
                          :to="{ name: 'page', params: { slug: child.slug } }"
                          >{{ child.name }}</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <router-link :to="{ name: 'page', params: { slug: 'regions' } }"
                  >Regions</router-link
                >
              </li>

              <li>
                <router-link :to="{ name: 'agencies' }">Agencies</router-link>
              </li>

              <li>
                <router-link
                  v-if="$auth.hasToken()"
                  :to="{ name: 'my-listings.index' }"
                  >My Listings</router-link
                >
              </li>
            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">
              <template v-if="!auth">
                <li>
                  <router-link :to="{ name: 'login' }" class="text-success">
                    <img
                      src="/assets/img/submit.svg"
                      width="20"
                      alt=""
                      class="mr-2"
                    />
                    Sign In
                  </router-link>
                </li>
                <li class="text-success">/</li>
                <li>
                  <router-link :to="{ name: 'register' }" class="text-success">
                    <img
                      src="/assets/img/submit.svg"
                      width="20"
                      alt=""
                      class="mr-2"
                    />
                    Sign Up
                  </router-link>
                </li>
              </template>
              <template v-if="auth">
                <li class="ml-3">
                  <router-link
                    :to="{ name: 'my-account.home' }"
                    class="text-success"
                  >
                    My Account
                  </router-link>
                </li>
                <li class="ml-3">
                  <a href="JavaScript:Void(0);" class="text-success">
                    <img
                      src="/assets/img/submit.svg"
                      width="20"
                      alt=""
                      class="mr-2"
                    />
                    {{ first_name }}
                    <span class="text-success submenu-indicator"></span>
                  </a>
                  <ul class="nav-dropdown nav-submenu">
                    <li v-if="role == 'admin'">
                      <router-link :to="{ name: 'admin.dashboard' }"
                        >Admin Dashboard</router-link
                      >
                    </li>
                    <li>
                      <a href="#" @click.prevent="logout">Logout</a>
                    </li>
                  </ul>
                </li>
              </template>
              <li class="add-listing theme-bg ms-3">
                <router-link :to="{ name: 'submit-property' }">
                  <img
                    src="/assets/img/user-light.svg"
                    width="12"
                    alt=""
                    class="mr-2"
                  />
                  Submit New Property
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import '@/theme.js'
import $ from "jquery";
import TopBar from './TopBar.vue'

export default {
  components: {
    TopBar
  },

  mounted() {
    !(function (n, e, i, a) {
      (n.navigation = function (t, s) {
        var o = {
            responsive: !0,
            mobileBreakpoint: 992,
            showDuration: 300,
            hideDuration: 300,
            showDelayDuration: 0,
            hideDelayDuration: 0,
            submenuTrigger: "hover",
            effect: "fade",
            submenuIndicator: !0,
            hideSubWhenGoOut: !0,
            visibleSubmenusOnMobile: !1,
            fixed: !1,
            overlay: !0,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            hidden: !1,
            offCanvasSide: "left",
            onInit: function () {},
            onShowOffCanvas: function () {},
            onHideOffCanvas: function () {},
          },
          u = this,
          r = Number.MAX_VALUE,
          d = 1,
          f = "click.nav touchstart.nav",
          l = "mouseenter.nav",
          c = "mouseleave.nav";
        u.settings = {};
        var t = (n(t), t);
        n(t)
          .find(".nav-menus-wrapper")
          .prepend("<span class='nav-menus-wrapper-close-button'>✕</span>"),
          n(t).find(".nav-search").length > 0 &&
            n(t)
              .find(".nav-search")
              .find("form")
              .prepend("<span class='nav-search-close-button'>✕</span>"),
          (u.init = function () {
            (u.settings = n.extend({}, o, s)),
              "right" == u.settings.offCanvasSide &&
                n(t)
                  .find(".nav-menus-wrapper")
                  .addClass("nav-menus-wrapper-right"),
              u.settings.hidden &&
                (n(t).addClass("navigation-hidden"),
                (u.settings.mobileBreakpoint = 99999)),
              v(),
              u.settings.fixed && n(t).addClass("navigation-fixed"),
              n(t)
                .find(".nav-toggle")
                .on("click touchstart", function (n) {
                  n.stopPropagation(),
                    n.preventDefault(),
                    u.showOffcanvas(),
                    s !== a && u.callback("onShowOffCanvas");
                }),
              n(t)
                .find(".nav-menus-wrapper-close-button")
                .on("click touchstart", function () {
                  u.hideOffcanvas(), s !== a && u.callback("onHideOffCanvas");
                }),
              n(t)
                .find(".nav-search-button")
                .on("click touchstart", function (n) {
                  n.stopPropagation(), n.preventDefault(), u.toggleSearch();
                }),
              n(t)
                .find(".nav-search-close-button")
                .on("click touchstart", function () {
                  u.toggleSearch();
                }),
              n(t).find(".megamenu-tabs").length > 0 && y(),
              n(e).resize(function () {
                m(), C();
              }),
              m(),
              s !== a && u.callback("onInit");
          });
        var v = function () {
          n(t)
            .find("li")
            .each(function () {
              n(this).children(".nav-dropdown,.megamenu-panel").length > 0 &&
                (n(this)
                  .children(".nav-dropdown,.megamenu-panel")
                  .addClass("nav-submenu"),
                u.settings.submenuIndicator &&
                  n(this)
                    .children("a")
                    .append(
                      "<span class='submenu-indicator'><span class='submenu-indicator-chevron'></span></span>"
                    ));
            });
        };
        (u.showSubmenu = function (e, i) {
          g() > u.settings.mobileBreakpoint &&
            n(t).find(".nav-search").find("form").slideUp(),
            "fade" == i
              ? n(e)
                  .children(".nav-submenu")
                  .stop(!0, !0)
                  .delay(u.settings.showDelayDuration)
                  .fadeIn(u.settings.showDuration)
              : n(e)
                  .children(".nav-submenu")
                  .stop(!0, !0)
                  .delay(u.settings.showDelayDuration)
                  .slideDown(u.settings.showDuration),
            n(e).addClass("nav-submenu-open");
        }),
          (u.hideSubmenu = function (e, i) {
            "fade" == i
              ? n(e)
                  .find(".nav-submenu")
                  .stop(!0, !0)
                  .delay(u.settings.hideDelayDuration)
                  .fadeOut(u.settings.hideDuration)
              : n(e)
                  .find(".nav-submenu")
                  .stop(!0, !0)
                  .delay(u.settings.hideDelayDuration)
                  .slideUp(u.settings.hideDuration),
              n(e)
                .removeClass("nav-submenu-open")
                .find(".nav-submenu-open")
                .removeClass("nav-submenu-open");
          });
        var h = function () {},
          p = function () {};
        (u.showOffcanvas = function () {
          h(),
            "left" == u.settings.offCanvasSide
              ? n(t)
                  .find(".nav-menus-wrapper")
                  .css("transition-property", "left")
                  .addClass("nav-menus-wrapper-open")
              : n(t)
                  .find(".nav-menus-wrapper")
                  .css("transition-property", "right")
                  .addClass("nav-menus-wrapper-open");
        }),
          (u.hideOffcanvas = function () {
            n(t)
              .find(".nav-menus-wrapper")
              .removeClass("nav-menus-wrapper-open")
              .on(
                "webkitTransitionEnd moztransitionend transitionend oTransitionEnd",
                function () {
                  n(t)
                    .find(".nav-menus-wrapper")
                    .css("transition-property", "none")
                    .off();
                }
              ),
              p();
          }),
          (u.toggleOffcanvas = function () {
            g() <= u.settings.mobileBreakpoint &&
              (n(t)
                .find(".nav-menus-wrapper")
                .hasClass("nav-menus-wrapper-open")
                ? (u.hideOffcanvas(), s !== a && u.callback("onHideOffCanvas"))
                : (u.showOffcanvas(),
                  s !== a && u.callback("onShowOffCanvas")));
          }),
          (u.toggleSearch = function () {
            "none" == n(t).find(".nav-search").find("form").css("display")
              ? (n(t).find(".nav-search").find("form").slideDown(),
                n(t).find(".nav-submenu").fadeOut(200))
              : n(t).find(".nav-search").find("form").slideUp();
          });
        var m = function () {
            u.settings.responsive
              ? (g() <= u.settings.mobileBreakpoint &&
                  r > u.settings.mobileBreakpoint &&
                  (n(t)
                    .addClass("navigation-portrait")
                    .removeClass("navigation-landscape"),
                  D()),
                g() > u.settings.mobileBreakpoint &&
                  d <= u.settings.mobileBreakpoint &&
                  (n(t)
                    .addClass("navigation-landscape")
                    .removeClass("navigation-portrait"),
                  k(),
                  p(),
                  u.hideOffcanvas()),
                (r = g()),
                (d = g()))
              : k();
          },
          b = function () {
            n("body").on("click.body touchstart.body", function (e) {
              0 === n(e.target).closest(".navigation").length &&
                (n(t).find(".nav-submenu").fadeOut(),
                n(t).find(".nav-submenu-open").removeClass("nav-submenu-open"),
                n(t).find(".nav-search").find("form").slideUp());
            });
          },
          g = function () {
            return (
              e.innerWidth ||
              i.documentElement.clientWidth ||
              i.body.clientWidth
            );
          },
          w = function () {
            n(t).find(".nav-menu").find("li, a").off(f).off(l).off(c);
          },
          C = function () {
            if (g() > u.settings.mobileBreakpoint) {
              var e = n(t).outerWidth(!0);
              n(t)
                .find(".nav-menu")
                .children("li")
                .children(".nav-submenu")
                .each(function () {
                  n(this).parent().position().left + n(this).outerWidth() > e
                    ? n(this).css("right", 0)
                    : n(this).css("right", "auto");
                });
            }
          },
          y = function () {
            function e(e) {
              var i = n(e).children(".megamenu-tabs-nav").children("li"),
                a = n(e).children(".megamenu-tabs-pane");
              n(i).on("click.tabs touchstart.tabs", function (e) {
                e.stopPropagation(),
                  e.preventDefault(),
                  n(i).removeClass("active"),
                  n(this).addClass("active"),
                  n(a).hide(0).removeClass("active"),
                  n(a[n(this).index()]).show(0).addClass("active");
              });
            }
            if (n(t).find(".megamenu-tabs").length > 0)
              for (
                var i = n(t).find(".megamenu-tabs"), a = 0;
                a < i.length;
                a++
              )
                e(i[a]);
          },
          k = function () {
            w(),
              n(t).find(".nav-submenu").hide(0),
              navigator.userAgent.match(/Mobi/i) ||
              navigator.maxTouchPoints > 0 ||
              "click" == u.settings.submenuTrigger
                ? n(t)
                    .find(".nav-menu, .nav-dropdown")
                    .children("li")
                    .children("a")
                    .on(f, function (i) {
                      if (
                        (u.hideSubmenu(
                          n(this).parent("li").siblings("li"),
                          u.settings.effect
                        ),
                        n(this)
                          .closest(".nav-menu")
                          .siblings(".nav-menu")
                          .find(".nav-submenu")
                          .fadeOut(u.settings.hideDuration),
                        n(this).siblings(".nav-submenu").length > 0)
                      ) {
                        if (
                          (i.stopPropagation(),
                          i.preventDefault(),
                          "none" ==
                            n(this).siblings(".nav-submenu").css("display"))
                        )
                          return (
                            u.showSubmenu(
                              n(this).parent("li"),
                              u.settings.effect
                            ),
                            C(),
                            !1
                          );
                        if (
                          (u.hideSubmenu(
                            n(this).parent("li"),
                            u.settings.effect
                          ),
                          "_blank" == n(this).attr("target") ||
                            "blank" == n(this).attr("target"))
                        )
                          e.open(n(this).attr("href"));
                        else {
                          if (
                            "#" == n(this).attr("href") ||
                            "" == n(this).attr("href")
                          )
                            return !1;
                          e.location.href = n(this).attr("href");
                        }
                      }
                    })
                : n(t)
                    .find(".nav-menu")
                    .find("li")
                    .on(l, function () {
                      u.showSubmenu(this, u.settings.effect), C();
                    })
                    .on(c, function () {
                      u.hideSubmenu(this, u.settings.effect);
                    }),
              u.settings.hideSubWhenGoOut && b();
          },
          D = function () {
            w(),
              n(t).find(".nav-submenu").hide(0),
              u.settings.visibleSubmenusOnMobile
                ? n(t).find(".nav-submenu").show(0)
                : (n(t).find(".nav-submenu").hide(0),
                  n(t)
                    .find(".submenu-indicator")
                    .removeClass("submenu-indicator-up"),
                  u.settings.submenuIndicator
                    ? n(t)
                        .find(".submenu-indicator")
                        .on(f, function (e) {
                          return (
                            e.stopPropagation(),
                            e.preventDefault(),
                            u.hideSubmenu(
                              n(this).parent("a").parent("li").siblings("li"),
                              "slide"
                            ),
                            u.hideSubmenu(
                              n(this)
                                .closest(".nav-menu")
                                .siblings(".nav-menu")
                                .children("li"),
                              "slide"
                            ),
                            "none" ==
                            n(this)
                              .parent("a")
                              .siblings(".nav-submenu")
                              .css("display")
                              ? (n(this).addClass("submenu-indicator-up"),
                                n(this)
                                  .parent("a")
                                  .parent("li")
                                  .siblings("li")
                                  .find(".submenu-indicator")
                                  .removeClass("submenu-indicator-up"),
                                n(this)
                                  .closest(".nav-menu")
                                  .siblings(".nav-menu")
                                  .find(".submenu-indicator")
                                  .removeClass("submenu-indicator-up"),
                                u.showSubmenu(
                                  n(this).parent("a").parent("li"),
                                  "slide"
                                ),
                                !1)
                              : (n(this)
                                  .parent("a")
                                  .parent("li")
                                  .find(".submenu-indicator")
                                  .removeClass("submenu-indicator-up"),
                                void u.hideSubmenu(
                                  n(this).parent("a").parent("li"),
                                  "slide"
                                ))
                          );
                        })
                    : k());
          };
        (u.callback = function (n) {
          s[n] !== a && s[n].call(t);
        }),
          u.init();
      }),
        (n.fn.navigation = function (e) {
          return this.each(function () {
            if (a === n(this).data("navigation")) {
              var i = new n.navigation(this, e);
              n(this).data("navigation", i);
            }
          });
        });
    })(jQuery, window, document),
      $(document).ready(function () {
        $("#navigation").navigation();
      });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".header").addClass("header-fixed");
      } else {
        $(".header").removeClass("header-fixed");
      }
    });
  },

  methods: {
    logout() {
      this.axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();

        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    }
  },

  computed: {
    first_name() {
      return this.$store.getters.getAuthUser.name.split(" ")[0];
    },

    categories() {
      return this.$store.getters.getMenuCategories;
    },

    auth() {
      return this.$auth.hasToken();
    },

    role() {
      return this.$store.getters.getAuthUser.role;
    },

    contact() {
      return  this.$store.getters.getContact
    },
  },
};
</script>

<style lang="scss" scoped>
.header .logo {
  margin-top: 0;
  margin-bottom: 0;
  float: none;
}
</style>